<template>
<div class='inputWrapper'>
    <span class='span25'>
	    <input :class='classHH' :style="'font-size: '+fontSize+'pt'" type="number" size="1" :disabled=disabled @keypress.enter="enterPressed()" @focus="$event.target.select()" v-model="hhVal" @change="changed"/>:
	    <input :class='classMM' :style="'font-size: '+fontSize+'pt'" type="number" size="1" :disabled=disabled @keypress.enter="enterPressed()" @focus="$event.target.select()" v-model="mmVal" @change="changed"/>:
	    <input :class='classSS' :style="'font-size: '+fontSize+'pt'" type="number" size="1" :disabled=disabled @keypress.enter="enterPressed()" @focus="$event.target.select()" v-model="ssVal" @change="changed"/>
    </span>
</div>
</template>
<script>
import clipboard from '@/components/Clipboard';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
export default {
  props: {
    value: { type: Number, default: 0},
	fontSize: { type: Number, default: 12},
	factor: { type: Number, default: 1},
    time: Number,
    disabled: { type: Boolean, default: false}
  },
  data(){
    return {
        hhVal: 0,
        mmVal: 0,
        ssVal: 0,
        classHH: "inpt_xxs",
        chassMM: "inpt_xxs",
        classSS: "inpt_xxs",
        getAppStyle
        }
  },
  components : {
    //clipboard
  },
  methods: {
      enterPressed() { this.changed(); this.$emit("enter"); },
      changed() { if ( this.check()) { this.parseTime(this.hhVal, this.mmVal, this.ssVal);} },
      check() {
          this.classHH = "inpt_xxs";
          this.classMM = "inpt_xxs";
          this.classSS = "inpt_xxs";
          let errorFound = false;

          if ( isNaN(parseInt(this.hhVal))) { this.classHH = "inpt_xxs error"; errorFound = true; }
          if ( isNaN(parseInt(this.mmVal))) { this.classMM = "inpt_xxs error"; errorFound = true; }
          if ( isNaN(parseInt(this.ssVal))) { this.classSS = "inpt_xxs error"; errorFound = true; }
          let val = parseInt(this.hhVal) * 3600;
          val += parseInt(this.mmVal) * 60;
          val += parseInt(this.ssVal);
          while ( val < 0 )
          {
          	val += 24*3600;
          }
          this.scanTime(val);
          
          //alert(this.hhVal +":"+this.mmVal +":"+this.ssVal );
          if ( !errorFound )
          {
            if ( this.hhVal < 0 || this.hhVal > 48 )
            {
                this.hhVal = 0;
                this.classHH = "xxs error";
                errorFound = true; 
            }
            if ( this.mmVal < 0 || this.mmVal > 59 )
            {
                this.mmVal = 0;
                this.classMM = "xxs error";
                errorFound = true; 
            }
            if ( this.ssVal < 0 || this.ssVal > 59 )
            {
                this.ssVal = 0;
                this.classSS = "xxs error";
                errorFound = true; 
            }
          }
          return !errorFound;
      },
      fmtTimePart( aTime) {
      	if ( aTime < 10 ) 
      	{
      		return "0"+aTime;
      	}
      	return ""+aTime;
      },
      scanAndCheckTime()
      {
        this.scanTime(this.value/this.factor);
        this.check();
       },
       scanTime(val)
       {
        //console.log( "scanTime("+val+") " + this.time )
        //alert("scanTime("+JSON.stringify(val)+") " + this.time)
        if ( isNaN( val ) || ! val  )
        {
        	val = 0;
        	this.parseTime(0,0,0);
        }
      	let ss = val % 60;
        let mm = ((val - ss) / 60 ) % 60;
        let hh = ((val - ss - mm * 60) / 3600 ) % 60;
        this.hhVal = this.fmtTimePart(hh);
        this.mmVal = this.fmtTimePart(mm);
        this.ssVal = this.fmtTimePart(ss);
        //alert(this.hhVal +this.mmVal +this.ssVal )
       },
       parseTime(hh,mm,ss)
       {
            let val = parseInt(hh) * 3600;
            val += parseInt(mm) * 60;
            val += parseInt(ss);
            this.$emit("input", val*this.factor)
			this.$emit("changed", val*this.factor)
       }
  },
  created() {
      this.scanTime(this.value/this.factor)
  },
  watch: {  
  	value: function() {
  		this.scanAndCheckTime()
      },
    time: function() {
  		this.scanAndCheckTime()
  	}
  },
  computed: {
    internalValue: {
      get() {
		let val = this.value/this.factor;
        let ss = val % 60;
        let mm = ((val - ss) / 60 ) % 60;
        let hh = ((val - ss - mm * 60) / 3600 ) % 60;
        
        let v = hh+":"+this.fmtTimePart(mm)+":"+this.fmtTimePart(ss);
        return v;
      },
      
      set(v) {
       // this.parseTime()
      }
    }
    }
 }
</script>
<style lang="scss" scoped>
input { font-size: 12pt; width: 22pt; border: none;  margin-left:0pt; margin-right: 0pt; padding: 0pt; }
input:disabled { font-size: 11pt; width: 14pt; border: none; color: #aaa; }
input.error { font-weight: bold; font-size: 12pt; width: 14pt; border: none; color: #444; background-color: #fcc;}
input:focus { outline: 1pt dotted grey; width: 22pt;}
inpt_xxs { font-size: 15pt; max-width: 22pt !important; border: none;  margin-left:0pt; margin-right: 0pt; padding: 0pt; }
inpt_xxs:disabled { font-size: 12pt; width: 22pt; border: none; color: #aaa !important; }
.span25 { display: inline-flex; padding: 0; margin: 0; height: 12pt; width: 100%; white-space: nowrap;}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 90%;
}

.inputButton { 
    border: 1pt solid grey;
}

</style>