<template>
<div>
    
    <div class='RDHeader' :style="getHeaderStyle()+';height: 24pt !important; display: flex; width: 99.8% !important;'">
		<div :style="getAppStyle()+'float: left; display: flex; background-color: transparent;'"  class='invers bold'>
        <div :style="';display: flex; float: left; height: 22pt !important; width: 75% !important;'">
        	<div style='width:18%'>
	        <InputDatePick :auto='false' style='font-size: 9pt; width:100px; height:28px;' @change="dateChanged" v-model="myDate"/>
	        </div>
	        <div style='width:60%'>
	        <vSelect placeholder='choose channel' :options="stations4DropDown" style='font-size: 8pt; width:100%' v-model="selectedStation" @input='stationChanged'> 
		    </vSelect>
	    	</div>
	    	<span style='padding-left: 2pt;'>
				<vSelect :options="statusList" style='font-size: 8pt; width: 120px;' v-model="selectedState" :multiple="false" @input="stateChanged()">  
				</vSelect>
			</span>
		</div>
		<div :style="'float: left; white-space: nowrap;'">
		<button :disabled='!getState().canCreatePlaylist' class='myButton button' :style="getAppStyle()+'margin: 0pt; height:28px;'" @click="getPL()"><mdicon :width="20" name="playlist-play"/>Playlist</button>
		<button v-if="station.createDummyAsRun" title='create fake asRun log file' class='myButton button' :style="getAppStyle()+'margin: 0pt; height:28px;'" @click="getAR('')">AsRun</button>
		
		<button v-if="station.createDummyAsRun" title='create fake asRun log file' class='myButton button' :style="getAppStyle()+'margin: 0pt; height:28px;'" @click="getAR('XLS')">AR2</button>
		<button class='myButton button' :style="getAppStyle()" title="Run copy-rotation"
		                 style='font-weight: normal; margin: 0pt; height:28px;' 
		                 @click="$emit('doCopyRotation', dayId)">
		                 <mdicon  :width="20" name="filmstrip-box-multiple" /></button>
		                 
		<button v-if="optimizerSettings" class='myButton button' :style="getAppStyle()" :title="'Run optimiser with settings \''+optimizerSettings.name+'\''" 
		        :disabled="getState().closed || !optimizerSettings" style='font-weight: normal; margin: 0pt; height:28px;' @click="$emit('runOptimizer', dayId)"><mdicon  :width="20" name="cart-arrow-down" /></button>
		<button v-if="clipboard" :style="getAppStyle()+'margin: 0pt; height:28px;'"
		        title='Move whole day to clipboard' :disabled="getState().closed" class='myButton button'  @click="$emit('dayToClipboard', station.id, date)"><mdicon  :width="20" name="cart-remove" /></button>
		</div>
		</div>
		<WindowCloseBN style='float: right;' @click="$emit('closeDay', dayId)"/>
		
    </div>
    
	<div class='transition noWrap' :style="getAppStyle()+'height: 18pt; padding-top: 1pt; width: 99.8% !important; margin: 0pt; padding: 0pt; font-size:8pt; overflow: hidden'"> <!--+getHeaderStyle()-->
		<div style="display: flex; float: left;">
		
			Revenue {{formatNumber(data.dayStats.revenueHC)+' '+sessionStorage.hCurrency}}, 
			Breaks {{breaks()}}, 
			Spots {{data.dayStats.spots}},
			Booked seconds {{ printTimeMMSS( secondsOnDay) }} of {{ printTimeMMSS( data.dayStats.capacity) }} 
		</div>
		
		<div v-if="data.dayStats && data.dayStats.capacity" style="display: flex; float: left; padding-left: 38pt; padding-top: 2px;">
			<GProgress  :height="10" :percentage="Math.round(100*data.dayStats.seconds/data.dayStats.capacity)"/>
		</div>
	</div>
 	<div :style="getAppStyle()+'width: 100%; height: calc(82.5vH); overflow-y: scroll; position: relative; font-size: 12px;'"
 	      @contextmenu.prevent="contextmenu($event, {})">
	    <div>	    
	    	<div v-for="(grid,idx1) in myData.gridAds" :key="'Grid'+idx1" :class='getSlotClass(grid, selectedPrograms)'>
		 	    <div class="RDOpenProgram" >
			 		<div class="RDProgram" :style="getAppStyle()+'height: 40pt; background-color: '+getBGColor('#eef','#226')+' !important; display: block !important; position: sticky; top: -1px;'">
			 		    <table style='table-layout: fixed;' width='100%' border="0">
			 		    <tr>
				 		    <td width="25%" title="program starttime" style='width: 100% !important; display: block !important; position: relative; text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden;'>
				 		        
				 		         {{ printTime2(grid.starttime)}} 
				 				<span v-show='grid.diff' class="RDProgramTime2">
					 				({{ printTime2(grid.starttime+grid.diff)}})
					 			</span>
					 	    </td>
					 	    
					 	    <td>
					 	        <span class="RDProgramTitle" :title='grid.detail.name'
					 	              @click="openGrid(grid)"
					 	              @contextmenu="contextmenuGrid($event, grid)"
					 	              style='cursor: pointer; width: 100% !important; display: flex !important; text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden;'>
						 			{{ grid.detail.name }}
						 			
						 		</span>
					 	    </td>
					 	    <td width="25%">
					 	        <span class="RDGridDurProgress">
			 			        <GProgress :height="11" :percentage="Math.round(100*sumGrid( grid.breaks)/grid.adDurationInSec)"/>
					    		</span>
					 	    </td>
			 		    </tr>
			 		    <tr>
				 		    <td>
				 		      <span class="RDBreakDur" style='margin: 0pt; width: 100% !important; display: inline-flex !important;text-overflow: ellipsis; white-space: nowrap; overflow-x: hidden;' title='usedTime vs. available ad-time in grid'>
					 			{{ printTimeMMSS( sumGrid( grid.breaks)) }} / 
					 			{{ printTimeMMSS( grid.adDurationInSec) }} 
					 		  </span>
				 		    </td>
				 		    <td  class='dontWrap'> 
				 		       
					 			<span class='singleExclusion' @click="$refs.editor.open('Exclusions', exc.id)" v-for="(exc,exi) in grid.exclusions" :key="'ex'+grid.detail-id+'.'+exi">
					 			{{exc.name}}
					 	        </span>
					 	     
						 	</td>
						 	<td>
						 	
						     <div v-if="clipboard" style='display: inline-flex; float: right;'  >
								<!--
						       <div v-if="(grid.adDurationInSec-sumGrid( grid.breaks)) > 0" style='display: inline-flex; cursor: pointer;'  
						            :title="'find best spots to add to ' +grid.detail.name"
						            @click="$emit('optimizeCB', station.id, clipboard, date, grid.detail, grid.adDurationInSec-sumGrid( grid.breaks))">
						       	<mdicon  :width="16" name="basket-fill" />
						       </div> 
						       
						       <div v-else style='display: inline-flex; cursor: pointer; color: #aaa;'  title="no ad-space left in this program"
						         >
						       	<mdicon  :width="16" name="basket-fill" />
						       </div> 
						      -->
						       <div v-if="clipboardFilter==grid.detail.id" style='display: inline-flex; cursor: pointer; color: red;' :title="'filter clipboard spots to match ' +grid.detail.name"
						            @click="clipboardFilter=0; $emit('filterCB', station.id, clipboard, {}, 0)">
						       	<mdicon  :width="16" name="clipboard" />
						       </div> 
						      
						       <div v-else style='display: inline-flex; cursor: pointer;' :title="'filter clipboard spots to match' +grid.detail.name"
						            @click="clipboardFilter=grid.detail.id; $emit('filterCB', station.id, clipboard, grid.detail, grid.adDurationInSec-sumGrid( grid.breaks))">
						         <mdicon :width="16" name="clipboard-outline" />
						       </div> 
						       </div>
						       <div style='display: inline-flex; float: right; margin-right: 5pt;' >
						 	
							 	 
						       </div>
				 		    </td>
			 		    </tr>
			 		    </table>
			 		   
			 		</div>
		
		 		<div  v-for="(brk,idx2) in grid.breaks" :class='getBreakClass(brk)' 
		 		     :key="'AS'+idx1+'.'+idx2">
		 				<div :id="'BR'+brk.brk.code" @contextmenu="contextmenu($event,null,brk)" 
						      :class="(selectCode==brk.brk.code)? 'RDBreakSelected' : 'RDBreak'" 
							  :style="editTime?'height: 25pt !important;':'height: 20pt !important;'" >
		 					
			 				<span class="RDBreakTime" title="break starttime">
			 				  
			 				    <!--  X{{brk.brk.identifier}}X -->
				 				{{printTime2(brk.space.estStarttime)}}
				 				<span v-show='grid.diff' class="RDBreakTime2">
					 				({{ printTime2(brk.space.estStarttime+grid.diff)}})
					 			</span>
				 			</span>
				 			
					 		<span :class="RDBreakTitle">
								
					 		    {{ brk.brk.code }} 
					 			{{ getBreakCode( brk.space, brk.type, brk) }}
					 		</span>
				 			<!-- 
					 		 -->
					 		
					 		<span class="RDBreakDur" title="booked duration/consumed time">
								 
					 			{{ printTimeMMSS( sum( brk.spots)) }} 
					 		</span>
					 		<span v-if="brk.brk.capacityInMS" class="RDBreakDur editable" title="plan duration / break capacity (open editor with click)">
					 		/  <span v-if="!editTime" @click="editTime=true" style="padding-left: 5pt;"> {{ printTimeMMSS( brk.brk.capacityInMS/1000) }}</span> 
							   
							   <div v-else style='display: inline-flex; margin-left: 5pt;'>
								   <InputTime fontSize="8" factor="1000" v-model="brk.brk.capacityInMS"/>
								   <button  title="save capacity" class="button myButton" :style="getAppStyle()+'height: 18pt;'" @click="updateCapacity( brk)"><mdicon size="14" name="content-save-outline"/></button>
								   <button  title="close capacity edit" class="button myButton" :style="getAppStyle()+'height: 18pt;'" @click="editTime=false"><mdicon size="14" name="close"/></button>
					 		   </div>
						   </span>
							<div  style='display: inline-flex; float:right;'>
								<button :disabled="brk.brk.disabled" title="disable break / remove from schedule" :style="getAppStyle()+'height: 16pt;margin-top: 0pt;padding-top: 0pt;'" class="button myButton" @click="hideBreak( brk)"><mdicon size="14" name="eye-off"/></button>
							</div>
					 		<div v-if="brk.brk.capacityInMS" class="RDBreakDurProgress" style='display: inline-flex; margin-top: 4pt; float:right;'>
					 			
					 			<GProgress :height="9" :treshold="Math.round(getTreshold( brk))" :percentage="Math.round(100000*sum( brk.spots)/brk.brk.capacityInMS)"/>
					 		</div>
							
							
		 				</div>
						<SimpleSpotList 
						    :ref="getRef(idx1, idx2)"
							@arrangeSpots=arrangeSpots 
							@moveSpots=moveSpots 
							@copyBreak=copyBreak 
							@moveBreak=moveBreak 
							@runIBO=runIBO
							@deleteSpot=deleteSpot
							@contextmenu=contextmenu
							@removeCopy=removeCopy
							@markXChangeable=markXChangeable
							@setPrice=setPrice
							@userUpdated=userUpdated
							:grid=grid
							:name="getRef(idx1,idx2)"
							:identifier=getRawKey(brk.brk)
							:update=update
							:fontSize=fontSize
							:dayId=dayId
							:user=user
							:selectedIN=selectedIN
							:spots="brk.spots?brk.spots:[]">
					    </SimpleSpotList>
		 		</div>
		 		</div>
		 	</div>
	
	 	</div>
	 </div>
	 
	 <ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuLabel v-if="contextData.spot">
        {{contextData.spot.placementName}}
        </ContextMenuLabel>
        <ContextMenuLabel v-else-if="contextData.grid">
        {{contextData.grid.detail.name}}
        </ContextMenuLabel>
        <ContextMenuSep/>
       <ContextMenuItem v-if="station && station.id" @clicked="closeCMN(); $refs.editor.open('Media', station.id)">
         Open {{tr("Media")}} {{ station.shortname }}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.spot && contextData.spot.placementId" @clicked="closeCMN(); openBookMan(contextData.spot)">
         Open Traffic Manager 
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.spot"/> 
        <ContextMenuItem v-if="contextData.spot && contextData.spot.copyId" @clicked="closeCMN(); $refs.editor.open('Copy', contextData.spot.copyId)">
         Open {{tr("Copy")}} '{{contextData.spot.copyName}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.spot && contextData.spot.placementId" @clicked="closeCMN(); $refs.editor.open('Placement', contextData.spot.placementId)">
         Open {{tr("Placement")}} 
        </ContextMenuItem>
		<ContextMenuSep v-if="contextData.brk"/> 
		<ContextMenuItem v-if="contextData.brk" @clicked="closeCMN(); hideBreak( contextData.brk);">
          HIDE {{ contextData.brk.brk.code }}
        </ContextMenuItem>
		<ContextMenuSep v-if="contextData.spot"/> 
        <ContextMenuItem v-if="contextData.spot && contextData.spot.placementId" @clicked="closeCMN(); showAllFilters( contextData.spot)">
         Show all {{tr('Inventory')}}
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.spot"/> 
         <ContextMenuItem v-if="contextData.spot" @clicked="closeCMN(); $refs[contextData.name][0].markAll();">
          Mark all
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.spot" @clicked="closeCMN(); toggleMarks(contextData)">
          Toggle marks
        </ContextMenuItem>
         <ContextMenuItem v-if="contextData.spot" @clicked="closeCMN(); $emit('breakToClipboard', contextData.spot)">
          move all spots in this break to clipboard
        </ContextMenuItem>
        <ContextMenuSep v-if="contextData.spot"/> 
        <ContextMenuItem v-if="contextData.spot" @clicked="closeCMN(); $refs[contextData.name][0].runInBreakOptimizer(contextData.spot);">
          In-Break Optimizer (this break)
        </ContextMenuItem>
         <ContextMenuSep/> 
         <ContextMenuItem v-if="optimizerSettings && contextData.spot" @clicked="closeCMN(); $emit('runOptimizer', dayId)">
          Run Optimizer '{{optimizerSettings.name}}'
        </ContextMenuItem>
         
        <ContextMenuItem v-if="contextData.spot" @clicked="closeCMN(); $emit('doPositioning', dayId)">
          Run In-Break-Optimizer (whole day)
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.spot" @clicked="closeCMN(); $emit('doCopyRotation', dayId)">
          Run Copy-Rotation
        </ContextMenuItem>
        <ContextMenuSep/> 
        <ContextMenuItem v-if="optimizerSettings" @clicked="closeCMN(); $refs.editor.open('OptimizerSettings', optimizerSettings.id)">
         Open {{tr("Optimizer Settings")}} '{{optimizerSettings.name}}'
        </ContextMenuItem>
        <ContextMenuItem v-if="false && contextData.spot" @clicked="closeCMN(); $emit('doPositioningDayBM', dayId)">
          Create Benchmark Input File
        </ContextMenuItem>
        
        
        <ContextMenuSep/> 
         
        <ContextMenuItem v-if="contextData.spot && contextData.spot.copyId" @clicked="closeCMN(); $refs[contextData.name][0].removeCopy(contextData.spot)">
         Remove copy
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.spot" @clicked="closeCMN(); $emit('removeFiller', dayId)">
         Remove all filler
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.spot && !contextData.spot.exchangeable" @clicked="closeCMN(); $refs[contextData.name][0].markXChangeable(contextData.spot, true)">
         Mark as exchangeable
        </ContextMenuItem>
         <ContextMenuItem v-if="contextData.spot && contextData.spot.exchangeable" @clicked="closeCMN(); $refs[contextData.name][0].markXChangeable(contextData.spot, false)">
         Remove mark as exchangeable
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.spot && contextData.spot.gross5" @clicked="closeCMN(); $refs[contextData.name][0].setPrice(contextData.spot, 0)">
          Set price to 0 
        </ContextMenuItem>
        <ContextMenuItem  v-if="contextData.spot && (contextData.spot.gross4 || (contextData.spot.gross4 == contextData.spot.gross5))"  @clicked="closeCMN(); $refs[contextData.name][0].setPrice(contextData.spot, -999)">
          Remove Fixed price
        </ContextMenuItem>
        <template v-if="contextData.spot"  >
             
	        <ContextMenuItem v-for="(stat, statIdx) in differentPlaced( contextData.spot)" :key="'BS_'+statIdx"  
	                         @clicked="closeCMN();$emit('setSpotStatus', contextData.spot, stat)">
	          Switch to {{ stat.name }}
	        </ContextMenuItem>
        </template>
         <ContextMenuItem  v-if="contextData.spot"  @clicked="closeCMN(); showHistory(contextData.spot)">
          Show spot log (history)
        </ContextMenuItem>
         
        
       </template>
    </ContextMenu>
    <GConfirm ref='rdconfirm' name="rdConfirm"/>
    <history ref='history'/>
    <GPrompt ref='prompt'/>
    <InfoPopUp :time=infoTime :infoType=asText :info=showAsText></InfoPopUp>
    <GFWEOpenEditor ref='editor' @update='$emit("reload")'></GFWEOpenEditor>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
</div>
</template> 
<script>
import { HTTP, APIURL, bngAPI, PlayListAPI, invAPI, gridAPI, myLocale, showError, inventoryInlineAPI, formatNumber} from '@/variables.js';
import { printTime2, printTimeMMSS } from '@/basicTimeFN.js';
import { setGoBack } from '@/breadCrumb.js';
import { getAppStyle, isDarkMode, adapt, getBGColor,getFGColor, } from '@/AppStyle.js';
import { tr } from '@/translate.js';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import history from '@/components/booking/HistorySpot';
import SimpleSpotList from '@/components/SimpleSpotList';
import InputDatePick from '@/components/inputElements/InputDatePick2';
import InputTime from '@/components/inputElements/InputTime3'; 
import GProgress from '@/components/misc/GProgressMedium';
import ProgressBar from '@/components/ProgressBar';
import InfoPopUp from '@/components/InfoPopUp';
import GConfirm from '@/components/GConfirm';
import GPrompt from '@/components/GPrompt';
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';
var momentTZ = require('moment-timezone')
var timers = [];
export default { 
  name: 'GFWRunDownView',
  components : {
   WindowCloseBN,GConfirm,GPrompt,history,ProgressBar,
   InputTime,
   SimpleSpotList, vSelect, InputDatePick, ContextMenu, ContextMenuItem, ContextMenuLabel, ContextMenuSep, GProgress, InfoPopUp
  },
  props: {
    refx: String,
	selectCode: String,
    station: Object,
    stations: Array,
    date: Date,
    fontSize: {type: Number, default: 9},
    showEmptyBreaks: Boolean,
    filterFor: String,
    selectedPrograms: Array,
	selectedRateTypes: Array,
	selectedPG: Array,
    selectedClients: Array,
    selectedProducts: Array,
    selectedCopies: Array,
    selectedBreaks: Array,
    optimizerSettings: Object,
    stateName: String,
    clipboard: String,
    placed: Array,
    timezone: Object,
    update: Number,
    dayId: String,
    user: Object,
    selectedIN: String,
    data: Object
  },
  data () {
    return {
      tr,
      formatNumber,
      stations4DropDown: Array,
      getBGColor,
      getFGColor,
	  editTime: false,
      secondsOnDay: 0,
      loadingActive: false,
      myData: {},
      myDate: this.date,
      diff: 0,
      clipboardFilter: 0,
      selectedState: {},
      selectedStation: {},
      statusList: [],
      controlsRight: false,
      sessionStorage,
      printTimeMMSS,
      getAppStyle,
      isDarkMode,
      asText: "",
      showAsText: "",
      infoTime: 0, 
	  // ProgBar
      pbAction: "",
      pbData: {},
      pbTitle: "",
      showProgressBar: false,
    }
  },
  methods: {
    myLocale() { return myLocale();},
    getTreshold( brk)
    {
		if ( this.station.freeAdSpaceAllowed )
		{
			100*(brk.brk.capacityInMS-this.station.freeAdSpaceAllowedInSeconds*1000)/brk.brk.capacityInMS
		}
		return 0;
	},
	action( what, myId, api)
	{
		this[what](myId, api)
	},
    getRawKey(brk)
    {
		if ( brk.typeValue==="BreakGrid" && brk.id )
		{
			return brk.id+"."+brk.date;
		}
		if ( brk.id )
		{
			return brk.id;
		}
   		//let gridId = brk.dayGridId ? brk.dayGridId: brk.gridId; 
   		return (brk.dayGridId?(brk.dayGridId+"."):"")+brk.gridId+":"+brk.mediaId+":"+brk.breakNo+":"+brk.breakTypeId+":"+brk.date;
    },
	hideBreak( brk )
	{
		this.$refs.rdconfirm.confirm( {text: "Disable this break", title: brk.brk.code, button1: "Cancel", button2: "Disable"}).then(x => { 
			HTTP.post( gridAPI+"/disableBreak/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+brk.brk.identifier)
	        .then( response => 
	        { 
	            
	        	this.$emit("reload")
	        
	        }).catch(e => {
	                showError( this.$toast, "hide break", e);
	          
	
	        });
		});
	},
	updateCapacity( brk )
	{
		let that = this;
		console.log( JSON.stringify( brk.brk))
		HTTP.post( gridAPI+"/updateCapacity/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+brk.brk.identifier+"/"+brk.brk.capacityInMS)
        .then( response => 
        { 
            
        	that.$toast.success("Capacity updated", 'Update', { position: "topRight" });
        
        }).catch(e => {
                showError( this.$toast, "hide break", e);
          

        });
	},
    getRef(idx1,idx2) { return "SSL_"+idx1+"."+idx2;},
    getHeaderStyle() {
    	if ( this.selectedState )
    	{
    		return "background-color: "+this.getState().colour+";";
    	}
    	return "";
    },
    showHistory( spot)
    {
    	HTTP.post( bngAPI+"/history/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+spot.id)
        .then( response => 
        { 
            this.$refs.history.open( response.data, spot);
        
        }).catch(e => {
                showError( this.$toast, "history", e);
          

        });
    },
    show( what)
    {
    	alert( JSON.stringify( what))
    },
    differentPlaced( spot)
    {
    	return this.placed.filter( p => p.id !== spot.statusId);
    },
    openGrid( grid)
    {
    	let dateISO = momentTZ(this.date).utcOffset(0, true).format().split('T')[0];
    	let repl = grid.detail.gridReplacements ? grid.detail.gridReplacements.find(p=>p.date==dateISO): null;
    	
    	if ( repl )
    	{
    		this.$refs.editor.open('GridDetailReplace', grid.detail.id)
    	}
    	else
    	{
    		this.$refs.editor.open('GridDetail', grid.detail.id)
    	}
    },
	dateChanged(date) {
		this.$emit("dateChanged", this.dayId, date)
	},
	stationChanged(selectedStation) {
		this.$emit("stationChanged", this.dayId, selectedStation.id)
	},
	copyBreak( target, source, fromId, toId) {
		this.$emit( "moveBreak", "copy", target, source, fromId, toId);
	},
	runIBO( key) {
		
		this.$emit( "runIBO", key);
	},
	showAllFilters( spot)
	{
		let that = this;
		HTTP.post( inventoryInlineAPI+"/getAllFilterForBookings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, spot)
		 .then( response => 
   		{
   			that.showAsText = "<ul style='padding-left: 3ex; list-style-type: circle;'>";
   			that.showAsText += "<li>"+tr('Placement')+": " + response.data.placementFilter.title+"</li>";
   			that.showAsText += "<li>"+tr('Client')+": " + response.data.clientFilter.title+ "</li>";
   			that.showAsText += "<li>"+tr('Product')+": " + response.data.productFilter.title+ "</li>";
   			that.showAsText += "<li>"+tr('Copy')+": " + response.data.copyFilter.title+ "</li>";
   			that.showAsText += "</ul>";
            //this.showAsText = JSON.stringify(response.data);
            that.infoTime = new Date().getTime();
            that.asText = "All "+tr("Inventory");
   		}).catch(e => {
            
            showError( that.$toast, "show filters", e)
    	});
	},
	sum( spots)
	{
		let dur = 0;
		for ( let i in spots )
		{
			dur += spots[i].duration;
		}
		return dur;
	},
	sumGrid( breaks)
	{
		let dur = 0;
		for ( let i in breaks )
		{
			dur += this.sum(breaks[i].spots);
		}
		return dur;
	},
	moveBreak( target, source, fromId, toId) {
		this.$emit( "moveBreak", "move", target, source, fromId, toId);
	},
	contextmenu(event, spot, brk){
	    event.preventDefault();
		if ( brk  )
		{
			let ctx = { brk: brk}
			this.$refs.menu.open(event, ctx );
	    }
		else
	    if ( spot && spot.spot )
	    {
        	this.$refs.menu.open(event, spot )
	    }
	},
	contextmenuGrid(event, grid){
	    event.preventDefault();
	    let dateISO = momentTZ(this.date).utcOffset(0, true).format().split('T')[0]
	    console.log( dateISO)
	    let repl = grid.replacements.find(p=>p.gridDetailId==grid.detail.id && p.date==dateISO);
	    
	    let ctx = { grid: grid, replace: repl}
        this.$refs.menu.open(event, ctx )
	},
	
	editRepl(data)
    {
		let dateISO = momentTZ(this.date).utcOffset(0, true).format().split('T')[0]
    	let repl = data.grid.gridReplacements ? data.grid.gridReplacements.find(p=>p.date==dateISO): null;
    	let name = repl?repl.name:data.grid.name;
    	let action = data.repl?"Edit":"Create";
    	
    	let that=this;
    	this.$refs.prompt.confirm( {text: action + ' replacement for ' + dateISO, title: data.grid.name, textField: name, button1: "Cancel", button2: "Create"}).then(x => {
    		let xdata = {id: data.repl?data.repl.id:0, name: x, gridDetailId: data.grid.id, date: dateISO };
    		HTTP.put( invAPI+"/editGridReplacement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, xdata)
            .then( response => 
            { 
                data.grid.name = x;
                this.$emit("reload")
            
            }).catch(e => {
                    showError( that.$toast, "remove Grid", e);
              

            });
    	});
    	
    },
    deleteRepl(data)
    {
    	//let d = date.year+"-"+date.monthValue+"-"+date.dayOfMonth;
    	let name = data.repl?data.repl.name:data.grid.name;
    	let action = data.repl?"Delete":"??";
    	let dateISO = momentTZ(this.date).utcOffset(0, true).format().split('T')[0]
    	let that=this;
    	this.$refs.rdconfirm.confirm( {text: action + ' replacement for ' + dateISO, title: data.grid.name, button1: "Cancel", button2: "Delete"}).then(x => {
    		let xdata = {id: data.repl?data.repl.id:0, gridDetailId: data.grid.id, date: dateISO };
    		HTTP.put( invAPI+"/deleteGridReplacement/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, xdata)
            .then( response => 
            { 
                data.grid.name = x;
               
                this.$emit("reload")
            
            }).catch(e => {
                    showError( that.$toast, "remove Grid", e);
              

            });
    	});
    	
    },
    addBreak( id, amount)
    {
    	//let d = date.year+"-"+date.monthValue+"-"+date.dayOfMonth;
    	
   
    		HTTP.put( invAPI+"/modifyBreaks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+id+"/"+amount)
            .then( response => 
            { 
                
            	this.$emit("reload")
            
            }).catch(e => {
                    showError( this.$toast, "modify Grid", e);
              

            });

    },
	closeCMN() { try{ this.$refs.menu.close(); } catch(e) { /* */ }},
	toggleMarks(ctx){
		if ( Array.isArray(this.$refs[ctx.name]))
		{
			this.$refs[ctx.name][0].toggleMarks();
		}
		else
		{
			this.$refs[ctx.name].toggleMarks();
		}
	},
	openBookMan( spot ) {
        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	this.$router.replace('bookMan?toOpen=Placement&list=true&id='+ spot.placementId);
    },  
	forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
	getPL() 
	{
		let txt = "Create Playlist/Log";
		
		if ( this.selectedStation.vm3 )
		{
			txt = txt + " (incl. sync VM3)";
		}
		this.$refs.rdconfirm.confirm( {text: txt, title: this.selectedStation.label+ ' Log-creation', button1: "Cancel", button2: "Create"}).then(x => { 
			let v = x;
			this.pbAction = "getPLIntern";
	        this.pbTitle = "create playlist/log";
	 	    this.showProgressBar = true;
		});
	},
	getPLIntern(myId, api) 
	{
		let dateStr = new Date(this.date).toISOString().split('T')[0];
		let that = this;
		//this.startLoader()
		//window.location.href = url;
		api.post( PlayListAPI+"/createPlaylist/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.station.id+"/"+dateStr+"/false/"+myId)
					 .then( response => 
	            { 
	               let downloadResponse = response.data;
	               if ( downloadResponse.dayStatus )
	               {

	               		that.$emit("statusUpdate", that.myData.date, that.myData.media.id, downloadResponse.dayStatus)
	               }
	               that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
				   that.showProgressBar = false;
				   //that.stopLoader();
	            }).catch(e => {
	                    that.showProgressBar = false;
	                    showError( that.$toast, "playlist creation error", e)

	            });
	           
	},
	getAR(type) 
	{
		let dateStr = new Date(this.date).toISOString().split('T')[0];
		let that = this;
		let url = APIURL+PlayListAPI+"/fakeAsRun/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.station.id+"/"+dateStr;
		console.log( url);
		//window.location.href = url;
		HTTP.post( PlayListAPI+"/fakeAsRun"+type+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.station.id+"/"+dateStr)
					 .then( response => 
	            { 
	               let downloadResponse = response.data;
	              
	               that.forceFileDownload( downloadResponse.file, downloadResponse.filename);

	            }).catch(e => {
	                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });

	            });
	           
	},
	printTime2( val) { return printTime2(val);},
	arrangeSpots( spots){ this.$emit("arrangeSpots", this.dayId, spots)},
	removeCopy( spots ) { this.$emit("removeCopy", spots); },
	markXChangeable( spots, flag ) { this.$emit("markXChangeable", spots, flag); },
	setPrice( spots, price ) { this.$emit("setPrice", spots, price); },
	userUpdated( user) { this.user=user; this.$emit("userUpdated", user );  },
	moveSpots( dndType, spots, target, source, fromId, toId){ this.$emit("moveSpots", dndType, spots, target, source, fromId, toId)},
	deleteSpot( spots, fromId ) { this.$emit("deleteSpot", spots, fromId) },
	getSlotClass(grid, selectedPrograms) {
	    let spots = grid.breaks.find( b => b.spots && b.spots.length);
		if ( (!selectedPrograms || !selectedPrograms.length || selectedPrograms.includes(grid.detail.name)) && (spots || this.showEmptyBreaks))
		{
			return "transition slot";
		}
		return "transition invisible ";
	},
	getBreakClass(brk)
	{
		if (brk.show && (this.showEmptyBreaks || (brk.spots && brk.spots.length)))
		{
			if ( brk.brk.disabled)
			{
				return ( brk.spots.length ) ? "transition hatchRed": "transition invisibleBreak ";
			}
			return "transition";
		}
		return "transition invisibleBreak "; 
	},
	getState()
	{
		if (!this.myData.statusList)
		{
			return {};
		}
		return this.myData.statusList.find( p => p.shortname === this.selectedState);
	},
	getBreakCode(adSpace, brk, fullBrk)
      {
        let code = "";
        if ( adSpace.section == 0 )
        {
        	code = "";
        }
        else if ( adSpace.section == 2 )
        {
        	code =  "";
        }
        else
        {
        	code =  "C-"+adSpace.no;
        }
        code += " "+brk.shortname;
        if ( fullBrk && fullBrk.detail)
        {
        	if ( fullBrk.detail.label && fullBrk.detail.label.length == 8)
        	{
        		code += " ("+fullBrk.detail.label.substring(0,2)+"."+fullBrk.detail.label.substring(2,4)+"."+fullBrk.detail.label.substring(4,6)+"."+fullBrk.detail.label.substring(6,8) +")";
        	}
        	else
        	{
        		code += " ("+fullBrk.detail.label+")";
        	}
        }
      	return code;
      },
	doFilterSpots( data)
    {
     	if ( this.filterFor.includes("All") || this.filterFor.includes("Rundown") )
     	{
	        for ( let iGrid in data.gridAds)
	        {
	        	
	        	let grid = data.gridAds[iGrid];
	        	if ( grid )
	        	{
	            	for ( let iBreak in grid.breaks)
	            	{
	            		let brk = grid.breaks[iBreak];
	            		if ( brk )
	        			{
	        				let show = true;
	        			    if ( this.selectedBreaks && this.selectedBreaks.length > 0 )
					     	{
					     		show = this.selectedBreaks.includes(brk.type.shortname)
					     	}
					     	brk.show = show;
					     	let tmp = [];
					     	if ( show )
					     	{
		        				tmp = brk.unfilteredSpots;
		        				if ( tmp )
		        				{
			        				if ( this.selectedPrograms && this.selectedPrograms.length > 0 )
							     	{
							     		tmp =  tmp.filter(spot => this.selectedPrograms.includes(spot.gridName) );
							     	}
							     	if ( this.selectedClients && this.selectedClients.length > 0 )
							     	{
							     		tmp =  tmp.filter(spot => this.selectedClients.includes(spot.clientName) );
							     	}
									if ( this.selectedRateTypes && this.selectedRateTypes.length > 0 )
							     	{
							     		tmp =  tmp.filter(spot => this.selectedRateTypes.includes(spot.rateType) );
							     	}
									if ( this.selectedPG && this.selectedPG.length > 0 )
							     	{
							     		tmp =  tmp.filter(spot => this.selectedPG.includes(spot.pricegroup) );
							     	}
							     	if ( this.selectedProducts && this.selectedProducts.length > 0 )
							     	{
							     		tmp =  tmp.filter(spot => this.selectedProducts.includes(spot.productName) );
							     	}
							     	if ( this.selectedCopies && this.selectedCopies.length > 0 )
							     	{
							     		tmp =  tmp.filter(spot => this.selectedCopies.includes(spot.copyName) );
							     	}
							    }
		                		
		                	}
		                	brk.spots = tmp;
	            		}	
	            	}
	            }
	        }
		}
     	else
     	{
	        for ( let iGrid in data.gridAds)
	        {
	        	
	        	let grid = data.gridAds[iGrid];
	        	if ( grid )
	        	{
	            	for ( let iBreak in grid.breaks)
	            	{
	            		let brk = grid.breaks[iBreak];
	            		if ( brk )
	        			{
	        				let show = true;
	        	
					     	brk.show = show;
					     	let tmp = [];
					     	tmp = brk.unfilteredSpots;
		                	brk.spots = tmp;
	            		}	
	            	}
	            }
	        }
		}
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
	   
    },
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	breaks()
	{
		let nBreaks = 0;
		if ( !Array.isArray( this.myData) && this.myData.gridAds )
		{
			for ( let gi in this.myData.gridAds)
			{
				let grid = this.myData.gridAds[gi];
				nBreaks += grid.breaks.length;
			}
		}	
		return nBreaks;
	},
	set( data)
	{
		if ( !Array.isArray( data) && data.gridAds )
		{
			this.myData = data;
			
			this.diff = data.gridAds.diff;
			this.doFilterSpots(this.myData);
			
			this.selectedState = this.stateName;
			this.secondsOnDay = data.dayStats.seconds;
			//alert( this.secondsOnDay+ JSON.stringify( data.dayStats))
			
			this.statusList = new Array();
			this.selectedStation = {id: this.station.id, label: this.station.shortname, vm3: this.station.synchVM3}
			//alert( JSON.stringify(this.station.synchVM3))
			//alert( JSON.stringify(this.stations))
			this.stations4DropDown = this.stations.map(p=>{ return { id: p.id, label: p.label, vm3: p.vm3, additionalColumn: p.additionalColumn}})
			this.stations4DropDown.sort( (a,b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0)
			//alert( JSON.stringify(this.stations4DropDown))
			for ( var i in data.statusList )
			{
				this.statusList.push( data.statusList[i].shortname )
			}
		}
	},
	reload(x)
	{
	    // alert( "DATA reload("+this.dayId+")");
		this.set( x);
	},
	stateChanged()
	{
		this.$emit("changeState", this.myData.date, this.myData.media.id, this.getState())
	}
  },
  updated() {
    this.$nextTick(function () {
  	//this.$refs.menu.open(event, {spot: {}, name: "" });
	//this.closeCMN();
	});
  },
  created() { 
  
	this.set( this.data);
	
	this.$nextTick(function () {
    try
    {
    	this.$refs.menu.open(event, {spot: {}, name: "" });
    } catch(e) { /* */ }
  	this.$refs.menu.close();
	});
  },
  
  watch: {
	selectCode: function() {
		if ( this.selectCode )
		{
			document.getElementById('BR'+this.selectCode).scrollIntoView({ behavior: "smooth", block: "center" });
		}
	},
    stateName: function() {
		this.set( this.data);
	},
	date: function() {
		this.set( this.data);
	},
	update: function() {
	    //alert( "update CHANGE" + this.update);
	    this.set( this.data);
	},
	selectedPrograms: function() {
		this.set( this.data);
	},
	filterFor: function() {
		this.set( this.data);
	},
	selectedCopies: function() {
		this.set( this.data);
	},
	selectedBreaks: function() {
		this.set( this.data);
	},
	selectedClients: function() {
		this.set( this.data);
	},
	selectedRateTypes: function() {
		this.set( this.data);
	},
	selectedPG: function() {
		this.set( this.data);
	},
	selectedProducts: function() {
		this.set( this.data);
	}
  },
 }
</script>

<style scoped>
.slot {
	box-shadow: 9pt 9pt 5pt 0 #99c;
	margin-top: 0px;
	margin-bottom: 20pt;
	margin-right: 4pt;
}
.hatchRed
{
	background: repeating-linear-gradient(-45deg, #f99, #f99 2px, white 2px, white 6px);
}
.invisible {
	visibility: hidden;
	position: absolute;
	color: #fff;
	background-color: #fff;
}
.invisibleBreak {
	display: hidden;
	height:0pt;
    -webkit-transform: scale(0.0);
        -ms-transform: scale(0.0);
        transform: scale(0.0);
}
.transition {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.transitionFast {
 	transition: all .2s;
   -webkit-transition: all .2s;
}
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  width: 100%;
  display: flex;
}
.noWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
}
.RDHeader {
    display: inline;
	width: 99% !important;
	font-size: 12px;
	height: 100% !important;
	font-weight: bold;
}
.RDHeaderText {
	padding-top: 3pt;
	padding-left: 2pt;
}
.RDProgramTitle {
    display: inline-flex;
    padding-left: 0pt;
   
	font-size: 14px;
	font-weight: bold;
	//background-color: #fff;
}
.RDBreakDur {
    display: inline-flex;
    //float: right;
    padding-left: 5pt;

	font-weight: bold;
	//background-color: #fff;
}
.editable {
	cursor: pointer;
}
.RDGridDurProgress {
	position: relative;
    display: inline-flex;
    float: right;
    //border: 1pt solid grey;
    align-items: center;
    justify-content: top;
    vertical-align: top !important;
    padding-top: 3pt;
    padding-left: 10pt;
    padding-right: 45pt;
 	padding-bottom: 0pt;
	font-weight: bold;
	//background-color: #fff;
}
.RDBreakDurProgress {
	display: inline-flex;
    float: right;
    align-items: center;
    justify-content: top;
    vertical-align: top !important;
    padding-left: 5pt;
    padding-right: 28pt;
    margin-top: 1pt;
	font-weight: bold;
	//background-color: #fff;
}
.RDProgramTime {
    display: inline-flex;
	font-size: 14px;
	width: 120px;
	color: #888;
	font-weight: bold;
	vertical-align: top !important;
}
.RDProgramTime2 {
    color: #888;
}
.RDProgram {
    display: flex;
	font-size: 14px;
	font-weight: bold;
	position: relative;
	height: 31pt;
	z-index: 1;
	//background-color: #fff;
}
.RDBreakTitle {
	
	font-weight: bold;
	
}
.RDBreakTime {
    display: inline-flex;
	width: 120px;
	color: #888;
	font-weight: bold;
	//background-color: #fff;
}
.RDBreakTime2 {
    display: inline-flex;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	//background-color: #fff;
}
.RDBreak {
	font-size: 11px;
	font-weight: normal;
	//background-color: #fff;
	border-top: 1px outset #aaa;
	text-align: left;
	padding-left: 6pt;
	padding-top: 2pt;
	 white-space: nowrap; /* Don't forget this one */
     text-overflow: ellipsis; 
     overflow: hidden;
     width: 100%;
}
.RDBreakSelected {
	font-size: 11px;
	font-weight: bold;
	color: #111;
	background-color: #aca;
	border-top: 1px solid #afa;
	text-align: left;
	padding-left: 6pt;
	padding-top: 2pt;
	 white-space: nowrap; /* Don't forget this one */
     text-overflow: ellipsis; 
     overflow: hidden;
     width: 100%;
}
.RDOpenProgram {
    margin-top: 2pt;
    
    margin-bottom: 8pt;
    padding: 2px;
	border-top: 1px outset #444;
	border-left: 1px outset #444;
	border-bottom: 1px outset #444;
}
.myButton {
	//width: 18%;
    padding: 4px 4px;
    //padding: 0pt;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    font-weight: normal;
    //background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-right: 4pt;
	z-index: 0 !important;
}
.invers {
	padding-left: 8pt; 
	padding-right: 4pt;
	width: 100%; 
	height: 18pt;
	padding-top: 1pt;
	color: #000; 
	background-color: #ccc;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 78% !importrant;
  display: flex;
}
.singleExclusion {
  border: 1pt dotted red; 
  color: #b00; 
  padding-right: 5pt; 
  float: left; 
  cursor: pointer;
}
.singleExclusion:hover {
  border: 1pt solid red; 
  color: red; 
  padding-right: 5pt; 
  float: left; 
  cursor: pointer;
}
</style> 